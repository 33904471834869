b,
strong {
  font-weight: 500 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

html,
body {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

#loading-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  pointer-events: none;
}
