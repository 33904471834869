.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 1;
  visibility: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader.fullscreen {
  background-color: rgba(150, 150, 150, 0.1);
}

.loader {
  z-index: 1;
  transition: visibility 0.3s cubic-bezier(0.38, 0.005, 0.215, 1), opacity 0.3s cubic-bezier(0.38, 0.005, 0.215, 1);
  visibility: visible;
  opacity: 1;
  overflow: visible;
  width: 60px;
}

.loader_inner {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  box-sizing: border-box;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.38, 0.005, 0.215, 1);
}

.loader_inner--droplet {
  margin: 0;
}

.loader_inner:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  -webkit-animation: loader-figure 1.5s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure 1.5s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-animation-play-state: running;
  animation-play-state: running;
  background-color: currentColor;
  z-index: 1;
}

.loader_inner:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  -webkit-animation: loader-label 1.5s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-label 1.5s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-animation-play-state: running;
  animation-play-state: running;
  background-color: currentColor;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
}

.loader_inner--droplet:before {
  border-radius: 0 50% 50% 50%;
}

.loader_inner--droplet:after {
  border-radius: 0 50% 50% 50%;
}

@keyframes loader-figure {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes loader-label {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
  }
  75% {
    opacity: 0;
    transform: scale(1.6);
  }
  100% {
  }
}

@-webkit-keyframes loader-figure {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loader-figure {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes loader-label {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
  }
  75% {
    opacity: 0;
    transform: scale(1.6);
  }
  100% {
  }
}

@keyframes loader-label {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
  }
  75% {
    opacity: 0;
    transform: scale(1.6);
  }
  100% {
  }
}
